<template>
  <div class="app flex-row login-main align-items-center">
    <div class="container" style="margin-top: 8%">
      <b-row class="justify-content-center">
        <b-col md="5">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form>
                  <b-img center src="images/logos/logo512.png" width="124px;" alt="Logo"></b-img>
                  <br />
                  <h1>Giriş Yap</h1>
                  <p class="text-muted">İst Medya cihazınıza giriş yapın.</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <b-img center src="images/icon/user.svg" width="11"></b-img>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="text" class="form-control" placeholder="Kullanıcı Adı yada Email"
                      autocomplete="username email" v-model="emailOrUserName" @keyup.enter="onEnter" ref="username" />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <b-img center src="images/icon/lock.svg" width="11"></b-img>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="password" class="form-control" placeholder="Şifre"
                      autocomplete="current-password" v-model="password" @keyup.enter="onEnter" ref="password" />
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button @click="login" variant="primary" class="px-4">Giriş Yap</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import http from "../shared/http";
import toast from "../shared/toast";
import jwtHelper from "../shared/jwt-helper";
export default {
  name: "loginForm",
  data() {
    return {
      loginTimer: null,
      emailOrUserName: "",
      password: "",
    };
  },
  created() { },
  mounted() {
    try {
      window.console.log("Login page" + new Date());
      window.console.log(localStorage.getItem("token"));

      if (localStorage.getItem("token")) {
        this.$router.push({ path: "/" });
      }

      this.loginTimer = setInterval(() => {
        window.console.log(this.$refs.password.$el.value);
        window.console.log(this.$refs.username.$el.value);
        if (
          this.$refs.password.$el.value &&
          this.$refs.username.$el.value &&
          this.$refs.password.$el.value.length > 3 &&
          this.$refs.username.$el.value.length > 3
        ) {
          this.emailOrUserName = this.$refs.username.$el.value;
          this.password = this.$refs.password.$el.value;
          if (navigator.onLine) this.login();
        }
      }, 30000);
    } catch (error) {
      window.console.log(error);
    }
  },
  methods: {
    onEnter: function () {
      this.login();
    },
    login() {
      try {
        if (this.emailOrUserName.length < 5) {
          toast.warning("Kullanıcı adınız en az 5 karakter olmalıdır.");
          return;
        }
        if (this.password.length < 5) {
          toast.warning("Şifreniz adınız en az 5 karakter olmalıdır.");
          return;
        }
        http
          .post("auth/login", {
            emailOrUserName: this.emailOrUserName,
            password: this.password,
          })
          .then((data) => {
            if (data && data.token) {
              localStorage.setItem("token", data.token);
              localStorage.setItem("expiration", data.expiration);
              if (!this.checkUserType()) return;
              window.location.href = "/";
            } else {
              toast.error(data);
              if (navigator.onLine) clearInterval(this.loginTimer);
            }
          })
          .catch((err) => {
            toast.error("Lütfen İnternetinizi kontrol edin ve tekrar deneyin");
            window.console.log(err);
            if (navigator.onLine) clearInterval(this.loginTimer);
          });
      } catch (error) {
        window.console.log(error);
        if (navigator.onLine) clearInterval(this.loginTimer);
      }
    },
    checkUserType() {
      if (!jwtHelper.isClient()) {
        toast.warning(
          "Sadece cihaz için oluşturulmuş kullanıcı türünden giriş yapabilirsiniz."
        );
        return false;
      }
      return true;
    },
  },
  beforeDestroy() {
    window.console.log('Login Vue destroyed');
    if (this.loginTimer)
      clearInterval(this.loginTimer);
  }
};
</script>
<style scoped>
.login-main {
  background-image: url("/images/bg/bg_main1.png");
  overflow: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: fixed;
}
</style>
