
import miniToastr from "mini-toastr";

miniToastr.init();

function success(message, title) {
  miniToastr.success(
    message,
    title ? title : "İşlem Başarılı",
    4000,
    undefined,
    undefined
  );
}

function info(message, title) {
  miniToastr.info(
    message,
    title ? title : "Bilgi",
    5000,
    undefined,
    undefined
  );
}

function warning(message, title) {
  miniToastr.warn(
    message,
    title ? title : "Uyarı!",
    6000,
    undefined,
    undefined
  );
}

function error(message, title) {
  miniToastr.error(
    message,
    title ? title : "Hata!",
    7000,
    undefined,
    undefined
  );
}

export default { success, info, warning, error }